<template>
  <div>
    <el-form
      @submit.native.prevent
      :model="calendardata"
      ref="calendardata"
      :rules="rules"
      label-width="130px"
    >
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="对象：" prop="moduleType">
            <el-select
              filterable
              v-model="calendardata.moduleType"
              placeholder="请选择对象"
              style="width: 360px"
              @change="changeObj"
              :disabled="calendaid"
            >
              <el-option value="人才">人才</el-option>
              <el-option value="客户">客户</el-option>
              <el-option value="项目">项目</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="calendardata.moduleType == '人才'">
          <el-form-item label="类型：" prop="scheduleType">
            <el-select
              filterable
              v-model="calendardata.scheduleType"
              placeholder="请选择类型"
              style="width: 360px"
            >
              <el-option value="联系候选人">联系候选人</el-option>
              <el-option value="候选人面试">候选人面试</el-option>
              <el-option value="其他">其他</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="calendardata.moduleType == '客户'">
          <el-form-item label="类型：" prop="scheduleType">
            <el-select
              filterable
              v-model="calendardata.scheduleType"
              placeholder="请选择类型"
              style="width: 360px"
            >
              <el-option
                v-for="(item, index) in khtypelist"
                :key="'khtype' + index"
                :value="item.val"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="calendardata.moduleType == '项目'">
          <el-form-item label="类型：" prop="scheduleType">
            <el-select
              filterable
              v-model="calendardata.scheduleType"
              placeholder="请选择类型"
              style="width: 360px"
              @change="changeType"
            >
              <el-option
                v-for="(item, index) in objtypelist"
                :key="'objtype' + index"
                :value="item.val"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="日期：" prop="scheduleTime">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              v-model="calendardata.scheduleTime"
              style="width: 360px"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注：" prop="comment">
            <el-input
              v-model="calendardata.comment"
              style="width: 360px"
              type="textarea"
              :maxlength="500"
              placeholder="请不要输入超过500个字"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item v-if="calendardata" label="任务对象：" prop="targetId">
            <calenderSelect
              :type="calendardata.moduleType"
              @gettargetId="getTargetId"
              @clean="cleantargetId1"
              :targetId1="targetId1"
              ref="calenderSelect"
            ></calenderSelect>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="calendardata.moduleType == '客户'">
          <el-form-item label="关联客户联系人：" prop="relatePersonId">
            <el-select
              style="width: 360px"
              filterable
              collapse-tags
              v-model="calendardata.relatePersonId"
              placeholder="请选择"
            >
              <el-option
                v-for="(i, index) in contactsList"
                :key="'contact' + index"
                :label="i.name"
                :value="i.id"
              >
              </el-option>
            </el-select>
            <i
              class="iconfont icon-xz_bd addContact"
              @click="addContact"
            ></i> </el-form-item
        ></el-col>
        <el-col
          :span="24"
          v-if="calendardata.moduleType == '项目' && !isrequired"
        >
          <el-form-item
            label="关联客户联系人："
            prop="relatePersonId"
            :rules="[
              {
                required: !isrequired,
                trigger: 'change',
                message: '请选择联系人',
              },
            ]"
          >
            <el-select
              style="width: 360px"
              filterable
              collapse-tags
              v-model="calendardata.relatePersonId"
              placeholder="请选择"
            >
              <el-option
                v-for="(i, index) in contactsList"
                :key="'contact' + index"
                :label="i.name"
                :value="i.id"
              >
              </el-option>
            </el-select>
            <i
              class="iconfont icon-xz_bd addContact"
              @click="addContact"
            ></i> </el-form-item
        ></el-col>
        <el-col
          :span="24"
          v-if="calendardata.moduleType == '项目' && isrequired"
        >
          <el-form-item
            label="关联候选人："
            prop="relatePersonId"
            :rules="[
              {
                required:
                  this.calendardata.scheduleType == '其他' ? false : isrequired,
                trigger: 'blur',
                message: '请选择候选人',
              },
            ]"
          >
            <el-select
              filterable
              v-model="calendardata.relatePersonId"
              placeholder="请选择"
              style="width: 360px"
            >
              <el-option
                v-for="(item, index) in relatePersonList"
                :key="'relate' + index"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="参与者：" class="fromInput" prop="joinUserIds">
            <el-select
              style="width: 360px"
              v-model="calendardata.joinUserIds"
              clearable
              filterable
              collapse-tags
              multiple
              ref="permissionRef"
              placeholder="请选择"
            >
              <el-option
                v-for="(i, index) in userList"
                :key="'user' + index"
                :label="i.name"
                :value="i.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      title="客户联系人"
      :visible.sync="dialogVisibleLinkMan"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handClose"
      width="600px"
      append-to-body
    >
      <el-form
        @submit.native.prevent
        :model="linkManFrom"
        ref="linkManFrom"
        :rules="rulesLink"
        style="padding: 0 50px"
        :validate-on-rule-change="false"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.name"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位：" prop="position">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.position"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="tel">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.tel"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.email"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信或其他：" prop="wechatOrOther">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.wechatOrOther"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisibleLinkMan = false;
            resetForm('linkManFrom');
          "
          size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="submitF('linkManFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserBaseInfos, //获取用户列表
  scheduleAddOrEdit, //新增编辑日程
  getScheduleById, //日程详情
  getContactsBaseInfos, //获取客户联系人
  getContactsBaseInfos1, //获取客户联系人
  comContactsAddOrEdit, //新增客户联系人
  GetsysResumeOption, //获取关联人
  calendarResumeOptionList, //人才任务列表
  CommonProjectPageOption, //获取日程的项目下拉选项列表
  CommonCompanyPageOption, //获取日程的客户下拉选项列表
} from "../../api/api";
import calenderSelect from "./calenderSelect.vue";
export default {
  components: {
    calenderSelect,
  },
  props: ["formdata", "Ryear"],
  data() {
    return {
      calendardata: {
        moduleType: "人才",
        comment: "",
        joinUserIds: [],
        scheduleType: null,
        scheduleTime: null,
        targetId: null,
        relatePersonId: null,
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "请给订阅搜索取个名字" },
        ],
        moduleType: [
          {
            required: true,
            trigger: "change",
            message: "请选择对象",
          },
        ],
        scheduleType: [
          {
            required: true,
            trigger: "change",
            message: "请选择类型",
          },
        ],
        scheduleTime: [
          {
            required: true,
            trigger: "change",
            message: "请选择日期",
          },
        ],
        targetId: [
          {
            required: true,
            trigger: "change",
            message: "请选择任务对象",
          },
        ],
        // relatePersonId: [
        //   {
        //     trigger: "change",
        //     message: "请选择联系人",
        //   },
        // ],
        // joinUserIds: [
        //   {
        //     required: true,
        //     trigger: "change",
        //     message: "请选择参与者",
        //   },
        // ],
      },
      khtypelist: [
        //客户类型

        {
          name: "客户拜访",
          val: "客户拜访",
        },
        {
          name: "客户邮件",
          val: "客户邮件",
        },
        {
          name: "客户电话",
          val: "客户电话",
        },
        {
          name: "其他",
          val: "其他",
        },
      ],
      objtypelist: [
        //客户类型
        {
          name: "顾问面试",
          val: "顾问面试",
        },
        {
          name: "客户面试",
          val: "客户面试",
        },
        {
          name: "候选人电话",
          val: "候选人电话",
        },
        {
          name: "候选人背调",
          val: "候选人背调",
        },
        {
          name: "offer谈判",
          val: "offer谈判",
        },
        {
          name: "候选人入职",
          val: "候选人入职",
        },
        {
          name: "候选人开票",
          val: "候选人开票",
        },
        {
          name: "试用期结束",
          val: "试用期结束",
        },
        {
          name: "客户电话",
          val: "客户电话",
        },
        {
          name: "客户拜访",
          val: "客户拜访",
        },
        {
          name: "客户邮件",
          val: "客户邮件",
        },
        {
          name: "其他",
          val: "其他",
        },
      ],
      missionObj: [], //任务对象
      missionObj1: [], //任务对象
      allChecked: [],
      contactsList: [],
      isrequired: false,
      relatePersonList: [],
      calendaid: false,
      rcfilter: {
        //人才任务对象查询条件
        filter: "",
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 150,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      rwtypelist: [
        //客户类型
        {
          name: "顾问面试",
          val: "顾问面试",
        },
        {
          name: "客户面试",
          val: "客户面试",
        },
        {
          name: "候选人电话",
          val: "候选人电话",
        },
        {
          name: "候选人背调",
          val: "候选人背调",
        },
        {
          name: "offer谈判",
          val: "offer谈判",
        },
        {
          name: "候选人入职",
          val: "候选人入职",
        },
        {
          name: "候选人开票",
          val: "候选人开票",
        },
        {
          name: "试用期结束",
          val: "试用期结束",
        },
        {
          name: "客户电话",
          val: "客户电话",
        },
        {
          name: "客户拜访",
          val: "客户拜访",
        },
        {
          name: "客户邮件",
          val: "客户邮件",
        },
        {
          name: "联系候选人",
          val: "联系候选人",
        },
        {
          name: "候选人面试",
          val: "候选人面试",
        },
        {
          name: "其他",
          val: "其他",
        },
      ],
      selectloading: false,
      selectnoMore: false,
      targetId1: 0,
      dialogVisibleLinkMan: false,
      linkManFrom: {
        companyId: null,
        projectId: null,
        email: "",
        id: 0,
        name: "",
        position: "",
        tel: "",
        version: 0,
        wechatOrOther: "",
      },
      rulesLink: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        position: [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位名称",
          },
        ],
      },
      userList: [],
      project: {
        status: "",
        managerId: [],
        positionCompanyName: "",
      },
    };
  },
  created() {
    this.calendardata = this.formdata;
    if (this.calendardata.id) {
      this.handleEdit();
    } else {
      this.$nextTick(() => {
        if (this.$refs.calenderSelect !== undefined) {
          this.$refs.calenderSelect.getpensonList();
        }
      });
    }
    this.getpensonList();
    this.loadUserBase();
    // this.$nextTick(() => {
    //   this.$refs.calenderSelect.getpensonList();
    // });
  },
  watch: {
    "project.companyIndustryIds"(newValue, oldValue) {
      if (newValue.length > 5) {
        this.$message({
          message: "最多不超过5个",
          duration: 1500,
          type: "error",
        });
        this.$nextTick(() => {
          this.project.companyIndustryIds = oldValue;
        });
      }
    },
  },
  methods: {
    //获取用户列表
    loadUserBase() {
      getUserBaseInfos(this.filter).then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.allChecked = [];
          for (let i in this.userList) {
            this.allChecked.push(this.userList[i].id);
          }
        }
      });
    },
    //对象修改
    changeObj(val, type) {
      this.missionObj = [];
      this.$refs["calendardata"].clearValidate();
      // if(this.calendaid){

      // }else{
      // //   this.resetForm("calendardata");
      // this.calendardata.targetId = null;
      // this.calendardata.relatePersonId = null;
      // }
      if (val === "人才") {
        // this.getpensonList();
        this.calendardata.moduleType = val;
        if (type) {
          this.calendardata.scheduleType = type;
        } else {
          this.calendardata.scheduleType = "联系候选人";
          this.calendardata.targetId = null;
        }
        // type
        //   ? (this.calendardata.scheduleType = type)
        //   : (this.calendardata.scheduleType = "联系候选人");
      } else if (val === "客户") {
        // this.getcompList();
        this.calendardata.moduleType = val;
        if (type) {
          this.calendardata.scheduleType = type;
        } else {
          this.calendardata.scheduleType = null;
          this.calendardata.targetId = null;
        }
        // type
        //   ? (this.calendardata.scheduleType = type)
        //   : (this.calendardata.scheduleType = null);
      } else if (val === "项目") {
        this.calendardata.moduleType = val;
        if (type) {
          this.calendardata.scheduleType = type;
        } else {
          this.calendardata.scheduleType = "顾问面试";
          this.calendardata.targetId = null;
        }
        // type
        //   ? (this.calendardata.scheduleType = type)
        //   : (this.calendardata.scheduleType = "顾问面试");
        if (
          this.calendardata.scheduleType === "客户电话" ||
          this.calendardata.scheduleType === "客户拜访" ||
          this.calendardata.scheduleType === "客户邮件"
        ) {
          this.isrequired = false;
          // this.$refs.calendardata.clearValidate('targetId');
        } else {
          this.isrequired = true;
          this.contactsOptions(this.calendardata.targetId);
        }
        // this.isrequired = false;
        // this.getobjList();
      }
      if (type) {
        this.getpensonList();
      } else {
        this.$nextTick(() => {
          if (this.$refs.calenderSelect !== undefined) {
            this.$refs.calenderSelect.getpensonList();
          }
        });
      }
    },
    //项目类型改变
    changeType(val) {
      if (val === "客户电话" || val === "客户拜访" || val === "客户邮件") {
        this.isrequired = false;
        // this.$refs.calendardata.clearValidate('targetId');
      } else {
        this.isrequired = true;
        this.contactsOptions(this.calendardata.targetId);
      }
    },
    //任务对象
    getTargetId(data) {
      // console.log(data)
      this.calendardata.targetId = data;
      this.targetId1 = data;
      if (this.calendardata.moduleType === "项目") {
        this.contactsOptions(this.calendardata.targetId);
      }
    },
    cleantargetId1() {
      this.targetId1 = 0;
      this.rcfilter = {
        //人才任务对象查询条件
        filter: "",
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 150,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      };
    },
    //任务对象改变
    changeTatget(val) {
      let isshow = false;
      if (
        this.calendardata.scheduleType == "客户电话" ||
        this.calendardata.scheduleType === "客户拜访" ||
        this.calendardata.scheduleType === "客户邮件"
      ) {
        isshow = true;
      }

      if (this.calendardata.moduleType === "客户") {
        this.loadContactsLitst(val, "");
        this.isrequired = true;
      } else {
        if (isshow) {
          this.loadContactsLitst(val, "");
          this.isrequired = false;
        } else {
          this.contactsOptions(val);
          this.isrequired = true;
        }
      }
    },
    //获取人才任务对象
    getpensonList(val) {
      this.rcfilter.filter = val;
      let req = null;
      if (this.calendardata.moduleType == "人才") {
        req = calendarResumeOptionList(this.rcfilter);
      } else if (this.calendardata.moduleType == "项目") {
        req = CommonProjectPageOption(this.rcfilter);
      } else if (this.calendardata.moduleType == "客户") {
        req = CommonCompanyPageOption(this.rcfilter);
      } else {
        return;
      }
      req.then((res) => {
        if (res.success) {
          this.missionObj1 = res.result.records;
          this.rcfilter.page.total = res.result.total;
          this.rcfilter.page.current = res.result.current;
        }
      });
    },
    // //获取客户任务对象
    // getcompList() {
    //   GetsysCompanyOption({ keyword: "" }).then((res) => {
    //     if (res.success) {
    //       this.missionObj = res.result;
    //     }
    //   });
    // },
    // //获取项目任务对象
    // getobjList() {
    //   GetsysProjectOption({ keyword: "" }).then((res) => {
    //     if (res.success) {
    //       this.missionObj = res.result;
    //     }
    //   });
    // },
    //获取客户联系人
    loadContactsLitst(id, type, relatePersonId) {
      let that = this;
      if (id) {
        if (this.calendardata.moduleType == "项目") {
          getContactsBaseInfos1({ projectId: id }).then((res) => {
            if (res.success) {
              that.contactsList = res.result;
              that.calendardata.relatePersonId = relatePersonId;
              if (!relatePersonId && isshow) {
                //新增
                that.calendardata.relatePersonId = null;
                if (res.result && res.result.length > 0) {
                  // this.calendardata.relatePersonId.push(res.result[0].id);
                  that.calendardata.relatePersonId = res.result[0].id;
                }
              } else {
                //编辑
                if (type == "sign") {
                  that.calendardata.relatePersonId = null;
                  if (res.result && res.result.length > 0) {
                    that.calendardata.relatePersonId = res.result[0].id;
                  }
                }
              }
            }
          });
        } else {
          getContactsBaseInfos({ companyId: id }).then((res) => {
            if (res.success) {
              that.contactsList = res.result;
              that.calendardata.relatePersonId = relatePersonId;
              if (!that.projectId) {
                //新增
                that.calendardata.relatePersonId = null;
                if (res.result && res.result.length > 0) {
                  // this.calendardata.relatePersonId.push(res.result[0].id);
                  that.calendardata.relatePersonId = res.result[0].id;
                }
              } else {
                //编辑
                if (type == "sign") {
                  that.calendardata.relatePersonId = null;
                  if (res.result && res.result.length > 0) {
                    that.calendardata.relatePersonId = res.result[0].id;
                  }
                }
              }
            }
          });
        }
      }
    },
    //关闭客户联系人弹窗
    handClose() {
      this.dialogVisibleLinkMan = false;
      this.resetForm("linkManFrom");
    },
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    //新增客户联系人弹窗
    addContact() {
      if (this.calendardata.targetId) {
        this.dialogVisibleLinkMan = true;
      } else {
        this.$message.error("请先选择任务对象");
      }
    },
    //删除左右两端的空格
    trim(str) {
      let a = "";
      a = str.replace(/(^\s*)|(\s*$)/g, "");
      return a;
    },
    //新增客户联系人
    submitF(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.linkManFrom.name = this.trim(this.linkManFrom.name);
          if (
            !this.linkManFrom.tel &&
            !this.linkManFrom.email &&
            !this.linkManFrom.wechatOrOther
          ) {
            this.$message.error("联系电话/邮箱/微信或其他至少填写1项");
            return false;
          }
          if (this.calendardata.moduleType == "项目") {
            this.linkManFrom.projectId = this.calendardata.targetId;
          } else {
            this.linkManFrom.companyId = this.calendardata.targetId;
          }

          comContactsAddOrEdit(this.linkManFrom).then((res) => {
            if (res.success) {
              this.$message.success("保存成功");
              this.dialogVisibleLinkMan = false;
              this.resetForm("linkManFrom");
              this.isrequired = false;
              //this.formdata.companyContactId=res.result 单选
              this.calendardata.relatePersonId = res.result;

              this.loadContactsLitst(
                this.linkManFrom.projectId
                  ? this.linkManFrom.projectId
                  : this.linkManFrom.companyId,
                "sign"
              );
            }
          });
        } else {
          return false;
        }
      });
    },
    //保存日程
    saveInfo() {
      //   this.saveloading = true;
      this.$refs["calendardata"].validate((valid) => {
        if (valid) {
          scheduleAddOrEdit(this.calendardata).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$emit("loadList");
              this.$emit("getlist", this.Ryear);
              this.$emit("close", "calendardata");
            } else {
              // this.upload_flag = false;
            }
          });
        }
      });
    },
    //编辑日程
    handleEdit(id) {
      getScheduleById(this.formdata.id).then((res) => {
        if (res.success) {
          this.calendardata.comment = res.result.comment
            ? res.result.comment
            : "";
          this.calendardata.joinUserIds = res.result.joinUserIds;
          this.calendardata.moduleType = res.result.moduleType;
          this.calendardata.id = id;
          this.calendardata.scheduleStatus = res.result.scheduleStatus;
          this.calendardata.scheduleTime = res.result.scheduleTime;
          this.calendardata.scheduleType = res.result.scheduleType;
          this.calendardata.targetId = res.result.targetId;
          this.calendardata.version = res.result.version;
          this.calendardata.relatePersonId = res.result.relatePersonId;
          this.changeType(this.calendardata.scheduleType);
          this.changeObj(
            this.calendardata.moduleType,
            this.calendardata.scheduleType
          );
          this.loadContactsLitst(
            this.calendardata.targetId,
            "",
            res.result.relatePersonId
          );
          this.contactsOptions(
            this.calendardata.targetId,
            res.result.relatePersonId
          );
          // if (this.calendardata.moduleType == "人才") {
          //   this.targetId1 = this.calendardata.targetId;
          // } else {
          //   this.targetId1 = 0;
          // }
          this.targetId1 = this.calendardata.targetId;
          this.canClick = true;
          this.calendarrwVisible = true;

          this.$nextTick(() => {
            if (this.$refs.calenderSelect !== undefined) {
              this.$refs.calenderSelect.getpensonList();
            }
          });
        }
      });
    },
    //关联候选人
    contactsOptions(id, relatePersonId) {
      if (id) {
        GetsysResumeOption({ id: id }).then((res) => {
          this.relatePersonList = res.result;
          this.calendardata.relatePersonId = relatePersonId;
        });
      } else {
        this.calendardata.relatePersonId = null;
      }
    },
  },
};
</script>
<style  lang="scss" scoped >
.addContact {
  color: #898989;
  font-size: 18px;
  position: absolute;
  display: inline-block;
  // right: -30px;
  left: 370px;
  top: 10px;
  height: 18px;
  line-height: 18px;
  &:hover {
    color: #e60012;
  }
}
</style>
