<template>
  <el-select
    ref="box"
    filterable
    v-model="id"
    placeholder="请选择"
    style="width: 360px"
    remote
    :remote-method="getpensonList"
    @change="getTargetId"
    @clear="getTargetId"
  >
    <el-option
      v-for="(item,index) in missionObj1"
      :key="'missionObj'+index"
      :value="item.id"
      :label="item.name"
    ></el-option>
  </el-select>
</template>

<script>
import {
  calendarResumeOptionList, //人才任务列表
  CommonCompanyPageOption, //获取日程的项目下拉选项列表
  CommonProjectPageOption, //获取日程的客户下拉选项列表
} from "../../api/api";
export default {
  props: ["targetId1", "type"],
  data() {
    return {
      //   targetId: "",
      id: null,
      missionObj1: [],
      rcfilter: {
        //人才任务对象查询条件
        filter: "",
        id: null,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      //   moveY:0
      scrollBar: {},
      wrap: {},
      isBottom: false,
      show: false,
    };
  },
  watch: {
    scrollBar: {
      handler(newVal, oldVal) {
        let height = newVal.moveY * 2.74 + 274 - 36 * this.rcfilter.page.size;
        if (height > 0 && !this.isBottom) {
          this.isBottom = true;
          this.rcfilter.page.current++;
          this.rcfilter.id = 0;
          this.getpensonList();
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    let box = this.$refs.box;
    let scrollBar = box.$refs.scrollbar;
    this.scrollBar = scrollBar._data;
  },
  methods: {
    //获取人才任务对象
    getpensonList(val) {
      let that = this;
      that.show = true;
      
      if (that.targetId1) {
        that.rcfilter.id = that.targetId1;
        that.id = that.targetId1;
      }
      if (val) {
        that.rcfilter.filter = val;
        that.rcfilter.page.current = 1;
        // that.rcfilter.id = null;
        that.id = that.targetId1;
        // that.targetId1 = null;
        this.$emit("clean");
      }
      let req = null;
      console.log(this.type)
      if (this.type == "人才") {
        req = calendarResumeOptionList(that.rcfilter);
      } else if (this.type == "项目") {
        req = CommonProjectPageOption(that.rcfilter);
      } else if (this.type == "客户") {
        req = CommonCompanyPageOption(that.rcfilter);
      }
      else{
        return;
      }
      req.then((res) => {
        if (res.success) {
          console.log(222)
          if (that.rcfilter.page.current == 1) {
            that.missionObj1 = res.result.records;
            //          if (that.targetId1) {
            //     that.rcfilter.id = that.targetId1;
            //   }
          } else {
            for (var i = 0; i < res.result.records.length; i++) {
              that.missionObj1.push(res.result.records[i]);
            }
          }
          that.rcfilter.page.total = res.result.total;
          that.rcfilter.page.current = res.result.current;
          if (res.result.pages == res.result.current) {
            that.isBottom = true;
            // alert("no");
          } else {
            setTimeout(() => {
              that.isBottom = false;
            }, 300);
          }
        }
      });
    },
    getTargetId(val) {
      this.$emit("gettargetId", val);
    },
  },
};
</script>